html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.custom-img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.unset-img {
  width: 100%;
}

.unset-img > span {
  position: unset !important;
}

/*.f04-bg {*/
/*  background: url("/images/fo4-bg.png") center center/cover no-repeat;*/
/*}*/
/*.f04-bg-events {*/
/*  background: url("/images/fo4-bg-events.png") center center/cover no-repeat, linear-gradient(rgb(229, 231, 235), rgb(26, 105, 101));*/
/*}*/

.f04-bg-videos {
  z-index: 0;
}

.post-reset img,
.post-reset svg,
.post-reset video,
.post-reset canvas,
.post-reset audio,
.post-reset iframe,
.post-reset embed,
.post-reset object {
  display: inline;
}

.youtube-content {
  white-space: pre-line;
  word-break: break-word;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
